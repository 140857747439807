var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"market"},[_c('base-header'),_c('div',{staticClass:"market_table"},[_c('div',{staticClass:"market_table_title"},[_c('ul',_vm._l((_vm.tableTitile),function(item){return _c('li',{key:item.index,staticClass:"tableTitile"},[(item.index == 1)?_c('div',{staticClass:"item",on:{"click":_vm.globSortTop}},[_c('p',[_vm._v(_vm._s(item.label))]),_c('div',[_c('div',[_c('i',{class:_vm.sortIndex == 'VOLUME24_ASC'
                      ? 'iconBlue el-icon-caret-top'
                      : 'icon el-icon-caret-top'})]),_c('div',[_c('i',{class:_vm.sortIndex == 'VOLUME24_DESC'
                      ? 'iconBlue el-icon-caret-bottom'
                      : 'icon el-icon-caret-bottom'})])])]):_vm._e(),(item.index == 2)?_c('div',{staticClass:"item",on:{"click":_vm.globSortTopH}},[_c('p',[_vm._v(_vm._s(item.label))]),_c('div',[_c('div',[_c('i',{class:_vm.sortIndex == 'ASSET_ASC'
                      ? 'iconBlue el-icon-caret-top'
                      : 'icon el-icon-caret-top'})]),_c('div',[_c('i',{class:_vm.sortIndex == 'ASSET_DESC'
                      ? 'iconBlue el-icon-caret-bottom'
                      : 'icon el-icon-caret-bottom'})])])]):_vm._e(),(item.index == 0)?_c('div',[_c('p',[_vm._v(_vm._s(item.label))])]):_vm._e()])}),0)]),_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(_vm.noData)?_c('div',[_vm._v("暂无数据")]):_vm._e(),[_c('van-list',{attrs:{"finished":_vm.finished,"offset":100},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"table_box"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"table_item",on:{"click":function($event){return _vm.godetail(item.exchangeId)}}},[_c('p',{staticClass:"sort selecet"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"number"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":item.logoUrl,"alt":""}}),_c('div',{staticClass:"exchange-name"},[_vm._v(_vm._s(item.exchangeName))])]),_c('div',{staticClass:"current_number",staticStyle:{"padding-top":"10px"}},[_c('peo-img',{attrs:{"visitVolume":item.visitVolume}})],1)]),_c('div',{staticClass:"TF_number"},[_c('p',[_vm._v(" "+_vm._s(_vm._f("formateMoneyM")(item.flowability))+" ")]),(item.transactionType!=null)?_c('ul',_vm._l((_vm.transactionType),function(i,index){return _c('li',{key:index},[_vm._v(_vm._s(i))])}),0):_vm._e()]),_c('div',{staticClass:"glob_number"},[_c('p',{staticClass:"glob_number_top"},[_vm._v(" "+_vm._s(_vm._f("formateMoneyM")(item.volume24))+" ")]),_c('p',{staticClass:"glob_number_bottom"},[_vm._v(" "+_vm._s(_vm._f("formateMoney")(item.assetValue))+" ")])])])}),0)])]],2)],1),(_vm.flag)?_c('base-footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }