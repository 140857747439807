<template>
  <div class="market">
    <base-header></base-header>
    <div class="market_table">
      <div class="market_table_title">
        <ul>
          <li class="tableTitile" v-for="item in tableTitile" :key="item.index">
            <div @click="globSortTop" class="item" v-if="item.index == 1">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndex == 'VOLUME24_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndex == 'VOLUME24_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div @click="globSortTopH" class="item" v-if="item.index == 2">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndex == 'ASSET_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndex == 'ASSET_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="item.index == 0">
              <p>{{ item.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div v-if="noData">暂无数据</div>
        <template>
          <van-list
            v-model="loading"
            :finished="finished"
            @load="onLoad"
            :offset="100"
          >
            <div class="table_box">
              <div @click="godetail(item.exchangeId)"
                class="table_item"
                v-for="(item, index) in list"
                :key="index"
              >
                <p class="sort selecet">{{ index + 1 }}</p>
                <div class="number">
                  <div class="pic">
                    <img :src="item.logoUrl" alt />
                    <div class="exchange-name">{{ item.exchangeName }}</div>
                  </div>
                  <div style="padding-top:10px" class="current_number">
                    <peo-img :visitVolume="item.visitVolume" />
                  </div>
                </div>
                <div class="TF_number">
                  <p>
                    {{ item.flowability | formateMoneyM }}
                  </p>
                  <ul v-if='item.transactionType!=null'>
                    <li v-for="(i,index) in transactionType" :key='index'>{{i}}</li>
                  </ul>
                </div>
                <div class="glob_number">
                  <p class="glob_number_top">
                    {{ item.volume24 | formateMoneyM }}
                  </p>
                  <p class="glob_number_bottom">
                    {{ item.assetValue | formateMoney }}
                  </p>
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <base-footer v-if='flag'></base-footer>
  </div>
</template>

<script>
import { getexchangesList, getexchanges } from '@/api/index';
import peoImg from '../../components/peo-img';
import baseHeader from '../../components/base-header';
import baseFooter from '../../components/base-footer';
export default {
  name: '',
  components: {
    baseHeader,
    baseFooter,
    peoImg
  },
  data() {
    return {
      flag: false,
      list: [],
      isLoading: false, // 下拉的加载图案
      loading: false, // 当loading为true时，转圈圈
      finished: false, // 数据是否请求结束，结束会先显示- 没有更多了 -
      noData: false, // 如果没有数据，显示暂无数据
      sortIndex: '',
      currentCurrency: '',
      pageNo: 1,
      pageSize: 20,
      transactionType: [],
      transactionTypeList: [],
      currencySymbol: {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      }
    }
    // gethalving
  },
  computed: {
    tableTitile: function() {
      return [
        {
          label: '',
          index: -1
        },
        {
          label: this.$t('平台'),
          index: 0
        },
        {
          label: this.$t(`流动性`) + `(${this.currencySymbol[this.currentCurrency]})`,
          index: 2
        },
        {
          label: this.$t('24H成交额') + `(${this.currencySymbol[this.currentCurrency]})`,
          index: 1
        }
      ];
    }
  },

  created() {
    // this.dealCurrentPath();
    // 判断当前语言
    // if (localStorage.getItem('currentNation')) {
    //   for (let n of this.nationList) {
    //     if (n.value == localStorage.getItem('currentNation')) {
    //       this.currentNation = n.label;
    //       this.$i18n.locale = n.value;
    //     }
    //   }
    // } else {
    //   this.currentNation = this.nationList[0].label;
    //   this.$i18n.locale = this.nationList[0].value;
    // }
    // 判断当前货币
    this.currentCurrency = localStorage.getItem('quoteCurrencyCode');
  },
  mounted() {
    this.getmarketcurrencies();
  },
  filters: {
    toDecomal: function(value) {
      let val = value ? value : 0;
      if (val.toFixed(2) == 0.0) {
        return val;
      } else {
        let percentageVal = val.toFixed(2);
        return parseFloat(percentageVal);
      }
    },
    formateMoney: function(value) {
      let currencyCode = localStorage.getItem('quoteCurrencyCode');
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let currencySymbol = {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      };
      let lastValue = '';
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    formateMoneyM: function(value) {
      let currencyCode = localStorage.getItem('quoteCurrencyCode');
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let rate = priceConversion[currencyCode].currencyPrice;
      let lastValue = '';
      lastValue = (value * rate).toFixed(4);
            lastValue = parseFloat(lastValue)
      if(lastValue == 0.0000) {
        lastValue = 0
      }
      let covertValue = (value * rate);//转换后的值
      if (currencyCode == 'CNY' && Number.parseInt(covertValue) / 100000000 >= 1) {
        let localeValue = (covertValue / 100000000);
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`
      } else if (currencyCode == 'CNY' && Number.parseInt(covertValue) / 10000 >= 1) {
        let localeValue = (covertValue / 10000);
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`
      } else if (Number.parseInt(covertValue) / 1000000000 >= 1) {
        let localeValue = (covertValue / 1000000000);
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`
      } else if (Number.parseInt(covertValue) / 1000000 >= 1) {
        let localeValue = (covertValue / 1000000);
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`
      }
      let str = `${lastValue}`;
      return str;
    } 
  },
  methods: {
    //详情页面
    godetail(id) {
      console.log(id,'id');
      this.$router.push({ path: "exchange/exchange-detail", query: { id: id } });
    },
    // 排序索引（VISIT_DESC/访问量倒序，VISIT_ASC/访问量顺序，ASSET_DESC/持有资金量倒序，ASSET_ASC/持有资金量顺序，VOLUME24_DESC/24小时成交额倒序，VOLUME24_ASC/24小时成交额顺序，CURRENCY_NUM_DESC/交易对倒序，CURRENCY_NUM_ASC/交易对顺序）,FLOW_DESC(流动性递减），FLOW_ASC（流动性递增)
    //排序
    globSortTop() {
      this.isLoading = true;
      this.pageNo = 1;
      this.list = [];
      if (
        this.sortIndex == '' ||
        this.sortIndex == 'ASSET_ASC' ||
        this.sortIndex == 'ASSET_DESC'
      ) {
        this.sortIndex = 'VOLUME24_ASC';
      } else if (this.sortIndex == 'VOLUME24_ASC') {
        this.sortIndex = 'VOLUME24_DESC';
      } else if (this.sortIndex == 'VOLUME24_DESC') {
        this.sortIndex = 'VOLUME24_ASC';
      }
      this.onLoad();
    },
    globSortTopH() {
      this.pageNo = 1;
      this.isLoading = true;
      this.list = [];
      if (
        this.sortIndex == '' ||
        this.sortIndex == 'VOLUME24_ASC' ||
        this.sortIndex == 'VOLUME24_DESC'
      ) {
        this.sortIndex = 'ASSET_ASC';
      } else if (this.sortIndex == 'ASSET_ASC') {
        this.sortIndex = 'ASSET_DESC';
      } else if (this.sortIndex == 'ASSET_DESC') {
        this.sortIndex = 'ASSET_ASC';
      }
      // this.getmarketcurrencies();
      this.onLoad();
    },
    //
    getmarketcurrencies() {
      getexchanges({
        language: this.$i18n.locale,
        sortIndex: this.sortIndex,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      })
        .then(res => {
          if (res.resultStatus) {
            this.loading = false;
            this.isLoading = false;
            this.pageNo++;
            // this.flag = true;
            for (let i = 0; i < res.resultData.dataList.length; i++) {
              this.list.push(res.resultData.dataList[i]);
            }

            if (this.list.length == 0 && this.pageNo === 1) {
              this.noData = true;
            }
            // 如果加载完毕，显示没有更多了
            if (res.resultData.dataList.length == 0) {
              this.finished = true;
              this.flag = true;
            }
            this.transactionTypeList = this.list.transactionType.split(';');
          }
        })
        .catch(() => {});
    },
    onLoad() {
      this.getmarketcurrencies();
      this.loading = true;
    },
    onRefresh() {
      // 重新初始化这些属性
      this.isLoading = false;
      this.list = [];
      this.pageNo = 1;
      this.pageSize = 20;
      this.loading = false;
      this.finished = false;
      this.noData = false;
      this.flag = false;
      // 请求信息
      this.getmarketcurrencies();
    }
  }
};
</script>

<style lang="scss" scoped>
.market {
  .market_top {
    padding: 0 28px 0 32px;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pic {
      width: 56px;
      height: 56px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 38px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 53px;
      padding-right: 30px;
    }
    .search {
      width: 76px;
      height: 48px;
      background: #edf5ff;
      border-radius: 24px;
      .iconfont {
        font-size: 24px;
        font-weight: bolder;
        color: #007aff;
        text-align: center;
        line-height: 48px;
        display: flex;
        justify-content: center;
      }
    }
    .flex_row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .language {
      padding: 0 15px;
      height: 48px;
      line-height: 48px;
      background: #edf5ff;
      border-radius: 24px;

      p {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0079ff;
      }
    }
    .currency {
      padding: 0 10px;
      height: 48px;
      line-height: 48px;
      background: #edf5ff;
      border-radius: 24px;
      p {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0079ff;
      }
    }
    .nav {
      width: 28px;
      height: 24px;
      img {
        width: 100%;
      }
    }
  }
  .marketValue {
    border-bottom: 20px solid #eff3f5;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 32px 47px 25px;
    .item_detail {
      .money {
        font-size: 22px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 30px;
        margin-bottom: 10px;
      }
      .item_name {
        justify-content: center;
        margin-bottom: 18px;
        display: flex;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        .item_name_mon {
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 30px;
        }
      }
    }
  }
  .market_nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .nav_item {
      padding-top: 24px;
      flex: 1;
    }
    .item_off {
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
    }
    .item_on {
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #007aff;
    }
    .line {
      height: 5px;
    }
  }
  .market_table {
    .market_table_title {
      ul {
        display: flex;
        align-items: center;
        line-height: 33px;
        background: #eff3f5;

        .tableTitile {
          padding: 10px 0;
          font-size: 24px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #a4afb5;
          display: flex;
          .item {
            display: flex;
            align-items: center;
          }

          &:nth-child(2) {
            text-align: left;
            padding-left: 24px;
            min-width: 36%;
            width: 36%;
          }
          &:nth-child(3) {
            display: flex;
            justify-content: flex-end;
            min-width: 26%;
            width: 26%;
          }
          &:nth-child(4) {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            min-width: 33%;
            width: 33%;
          }
          p {
            text-align: center;
            display: inline-block;
            margin-right: 2px;
          }

          .icon {
            margin: -12px 0;
            display: block;
          }
          .iconBlue {
            margin: -12px 0;
            display: block;
            color: #007aff;
          }
        }
      }
    }
    .table_box {
      .table_item {
        padding: 0 24px;
        display: flex;
        height: 160px;
        &:first-child {
          .selecet {
            color: #ff7600;
          }
        }
        &:nth-child(2) {
          .selecet {
            color: #f1ab19;
          }
        }
        &:nth-child(3) {
          .selecet {
            color: #96bfec;
          }
        }
        .sort {
          font-size: 36px;
          font-family: DINAlternate, DINAlternate-Bold;
          font-weight: 400;
          text-align: left;
          color: #a4afb5;
          line-height: 160px;
          width: 8%;
          min-width: 8%;
        }
        .number {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 160px;
          min-width: 28%;
          width: 28%;
          .pic {
            display: flex;
            align-items: center;
            img {
              margin-right: 16px;
              width: 40px;
              height: 40px;
            }
            .exchange-name {
              font-size: 28px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              font-size: 28px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
              line-height: 40px;
            }
          }
          .current_number {
            display: flex;
            align-items: center;
            font-size: 22px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 30px;
            p {
              margin-right: 8px;
            }
          }
        }
        .glob_number {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-width: 37%;
          width: 37%;
          .glob_number_top {
            font-size: 34px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: right;
            color: #333333;
            line-height: 50px;
          }
          .glob_number_bottom {
            margin-top: 9px;
            font-size: 24px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: right;
            color: #999999;
            line-height: 30px;
          }
        }
        .TF_number {
          min-width: 28%;
          width: 28%;
          p {
            font-size: 34px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
            text-align: right;
            color: #333333;
            line-height: 120px;
          }
          ul {
            display: flex;
            justify-content: flex-end;
          }
          li {
            width: 40px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #999999;
            line-height: 28px;
            border: 1px solid #dddddd;
            border-radius: 3px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
</style>
